import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { DeviceService, DslService, Logger, NativeAppService, NavigationService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import {
    ExtendedTopMenuClientConfig,
    MvsFeaturesClientConfig,
    MvsGamesClientConfig,
    MvsPageClientConfig,
} from '../client-config/virtualsports-client-config.models';
import { GameService } from '../shared/game.service';
import { VSNavigationService } from '../shared/navigation.service';

@Component({
    standalone: true,
    selector: 'vs-nav',
    templateUrl: 'navigation.component.html',
    imports: [CommonModule, RouterModule],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    isMobile: boolean = true;
    timeOutVar: any;
    loadingIndicator: any;
    showGameTitle: boolean;
    AZSports: string;
    categories: any = [];
    moreCategory: any = [];
    categoriesLength: any;
    totalNavBarWidth: any;
    currentWidth = 0;
    opacity = 1;
    arr: any;
    iter: any;
    moreText: any;
    hideMore: boolean = true;
    isVirtualsInMore: boolean = false;
    isTouchDevice: Boolean = true;
    screenWidth: any;
    games: any = [];
    currentrelativePath: any = this.router.url;
    currentUrlSpecificString: any;
    currentUrlSpecificStringArray: any;
    categoryRelativeUrlTemplate: string = this.mvspage.prefix;
    sportsRelativeUrlTemplate: string;
    activelinkstatus: boolean;
    gameobject: any;

    beforeSportslist: any = [];
    sportItemslist: any = [];
    afterSportslist: any = [];
    enableSportsNav: boolean;
    extendTopMenu: any;
    extendTopMenuState: boolean;
    extendedTopMenuToRight: boolean;
    currentUrl: any;
    navTopMenuList: any = [];
    extendMenuDslSubscription: Subscription;
    extendMenuDslEvalCompleted: boolean;
    isExtendTopMenuCalledfromOnChanges: boolean;
    firstGameName: string;
    isTopMenu: boolean;
    launchGameFromIframe: boolean = false;

    @ViewChild('sportsNav') sportsNav: ElementRef;
    // redirectMap: any = { 'beforeSport': this.performAction, 'sportItem': this.goToSport, 'afterSport': this.performAction };
    constructor(
        public elementRef: ElementRef,
        public mvspage: MvsPageClientConfig,
        private navigation: NavigationService,
        // public page: PageService,
        public nav: VSNavigationService,
        private ref: ChangeDetectorRef,
        private nativeAppService: NativeAppService,
        private gameService: GameService,
        private deviceService: DeviceService,
        public mvsgame: MvsGamesClientConfig,
        private router: Router,
        public route: ActivatedRoute,
        private featuresClientConfig: MvsFeaturesClientConfig,
        private extendedMenuConfig: ExtendedTopMenuClientConfig,
        private dslService: DslService,
        private logger: Logger, // private user:UserService,
    ) {
        this.gameService?.activeRouteUrl.asObservable().subscribe((urlData: any) => {
            if (urlData) {
                this.setHiglightUrl(urlData);
                this.gameobject = urlData;
                this.launchGameFromIframe = true;
            }
        });
        this.sportsRelativeUrlTemplate = this.mvspage.sportsPage + '/sports/live';
    }

    ngOnInit(): void {
        this.games = this.mvsgame.games;
        this.firstGameName = this.games[0].gameName;
        this.enableSportsNav = this.featuresClientConfig.enableAllSportsInNavigation;
        this.AZSports = this.mvspage.sportsTitle;
        this.isMobile = window.innerWidth < 768 ? true : false;
        this.extendTopMenuState = this.featuresClientConfig.extendedTopMenuStatus;
        this.extendedTopMenuToRight = this.featuresClientConfig.extendedTopMenuToRight;
        this.extendTopMenu = this.extendedMenuConfig.extendTopMenu;
        if (this.extendTopMenuState && this.extendTopMenu && this.extendTopMenu.length > 0 && !this.launchGameFromIframe) {
            this.extendTopMenu = this.extendedTopMenuToRight || this.isMobile ? this.extendTopMenu : this.extendTopMenu.reverse();
            this.extendTopMenu.forEach((item: any) => {
                this.extendedTopMenuToRight ? this.games.push(item) : this.games.unshift(item);
            });
        }
        if (this.featuresClientConfig.enableBetSearchApi) this.nav.getSportNavigation();
        this.beforeSportslist = this.nav.navigation.beforeSports;
        this.afterSportslist = this.nav.navigation.afterSports;

        // this.isMobile = window.innerWidth < 768 ? true : false;
        // eslint-disable-next-line no-var
        const sportInterval = setInterval(() => {
            if (this.nav.navigation.sports.length > 0) {
                this.games = [];
                this.moreCategory = [];
                this.games = this.mvsgame.games;
                this.sportItemslist = this.nav.navigation.sports;
                this.makeCategories();
                this.pushToCatergoryDivison();
                this.sportItemslist.forEach((sp: any) => {
                    this.games.push({
                        title: sp.tag.name.value,
                        gameName: sp.tag.id,
                        vendorId: 'live_sports',
                    });
                });
                this.isMobile || document.querySelector('.desk-main-items')!.setAttribute('style', 'overflow:hidden !important');
                clearInterval(sportInterval);
                setTimeout(() => {
                    this.navigationLinksHandler();
                    this.setHiglightUrl(this.gameobject);
                    this.isMobile || document.querySelector('.desk-main-items')!.setAttribute('style', 'overflow:unset !important');
                }, 50);
            }
        }, 50);
        this.pushToCatergoryDivison();

        window.addEventListener('scroll', this.scroll, true);

        this.moreText = this.mvspage.messages['MoreText'];
        if (!this.moreText) this.moreText = 'More';

        this.checkForTouchDevice();
        this.screenWidth = window.innerWidth;
        if (!this.isExtendTopMenuCalledfromOnChanges) this.evaluateExtendTopMenu();
        this.currentUrl = this.navigation.location.baseUrl() + this.navigation.location.path();
        this.addingPathNameInExtendTopMenu();
    }

    addingPathNameInExtendTopMenu() {
        if (this.extendTopMenu)
            this.extendTopMenu.forEach((data: any) => {
                if (this.isVirtualDomain(data.url)) {
                    data.pathname = new URL(data.url).pathname;
                }
            });
    }

    isVirtualDomain(url: string) {
        const virtualHomePage: string = this.mvspage.virtualsportsPage;
        return url.includes(virtualHomePage);
    }

    ngOnchanges() {
        this.evaluateExtendTopMenu();
        this.isExtendTopMenuCalledfromOnChanges = true;
    }

    ngAfterViewInit() {
        if (this.nav.navigation.sports.length == 0) {
            if (this.sportsNav.nativeElement.clientWidth == 0) {
                const sportInterval = setInterval(() => {
                    if (this.sportsNav.nativeElement.clientWidth > 0) clearInterval(sportInterval);
                    this.reloadNavigation();
                }, 1000);
            } else {
                this.reloadNavigation();
            }
        }
    }

    redirectMap(key: any, data: any) {
        if (key == 'beforeSport' || key == 'afterSport') this.performAction(data);
        else this.goToSport(data);
    }
    launchGameInIframe(game: any) {
        this.setGameDataInfo(game);
        this.setHiglightUrl(game);
        this.categoryUrl(game);
    }

    setGameDataInfo(game: any) {
        if (game.vendorId == 'live_sports') return;
        this.gameService?.gameDataInfo(game);
    }

    categoryUrl(category: any) {
        if (this.extendTopMenuState && category.isTopMenu == 'true') this.navigation.goTo(category.url);
        else if (category?.vendorId != 'live_sports') this.navigation.goTo(this.categoryRelativeUrlTemplate + '/' + category?.gameName);
        else if (category?.vendorId == 'live_sports') this.navigation.goTo(this.sportsRelativeUrlTemplate + '/' + category?.gameName);
    }

    setHiglightUrl(game: any) {
        if (this.extendTopMenuState && !game.isTopMenu) this.firstGameName = game?.gameName;
        else this.currentUrlSpecificString = game?.gameName;
        this.moreActiveClassCheck(game);
    }

    moreActiveClassCheck(category: any) {
        this.activelinkstatus = false;
        for (const cat of this.moreCategory) {
            if (cat?.gameName === category.gameName) {
                this.activelinkstatus = true;
                break;
            }
        }
    }

    performAction(link: any) {
        this.nav.performItemAction(link);
    }

    goToSport(sportId: any) {
        this.navigation.goTo(this.sportsUrl() + '/live/' + sportId);
    }

    goToEventsAll() {
        const goToEventAllUrl = this.goToEventsAllUrl();
        this.navigation.goTo(goToEventAllUrl);
    }

    goToEventsAllUrl() {
        return this.sportsUrl() + '/events/all';
    }

    sportsUrl() {
        return this.mvspage.sportsPage + '/sports';
    }

    sportItems() {
        return this.nav.navigation.sports;
    }

    beforeSports() {
        return this.nav.navigation.beforeSports;
    }

    afterSports() {
        return this.nav.navigation.afterSports;
    }
    trackbysport(index: any) {
        return index;
    }

    stickyPosition(cond: any, height: any) {
        const sportsnav = document.getElementById('sports-nav');
        const lobby = document.getElementsByClassName('lobby')[0];

        if (sportsnav && lobby && cond === 'add') {
            sportsnav.setAttribute('style', ' top: ' + height + 'px !important; position: fixed!important;');
            lobby.setAttribute('style', ' margin-top: 3.125em !important;');
        } else if (sportsnav && lobby && cond === 'remove') {
            sportsnav.setAttribute('style', ' top: inherit !important; position: relative !important;');
            lobby.setAttribute('style', ' margin-top: unset !important;');
        }
    }

    scroll = (): void => {
        let contentMessages;
        if (
            document.getElementsByTagName('vn-top-messages').length > 0 &&
            document.getElementsByTagName('vn-top-messages')[0].getElementsByTagName('vn-content-messages').length > 0
        ) {
            contentMessages = document
                .getElementsByTagName('vn-top-messages')[0]
                .getElementsByTagName('vn-content-messages')[0]
                .getElementsByClassName('content-message');
        }
        if (
            document.getElementsByTagName('vn-header').length > 0 &&
            document.getElementsByTagName('vn-header')[0].getElementsByTagName('vn-content-messages').length > 0
        ) {
            contentMessages = document
                .getElementsByTagName('vn-header')[0]
                .getElementsByTagName('vn-content-messages')[0]
                .getElementsByClassName('content-message');
        }

        const scroll = scrollY;
        const height = contentMessages && contentMessages.length !== 0 ? contentMessages[0].scrollHeight : 0;

        if (scroll >= height && document.getElementById('main-header')) {
            const navTop = document.getElementById('main-header')!.scrollHeight - 1;
            this.stickyPosition('add', navTop);
        } else {
            this.stickyPosition('remove', false);
        }
    };

    onResize() {
        if (window.innerWidth == this.screenWidth) return;

        this.isMobile = window.innerWidth < 768 ? true : false;
        clearTimeout(this.timeOutVar);
        // eslint-disable-next-line no-var
        const tempInterval = setInterval(function () {
            if (document.querySelector('.desk-main-items')) {
                document.querySelector('.desk-main-items')!.setAttribute('style', 'overflow:hidden');
                clearInterval(tempInterval);
            }
        }, 50);
        this.timeOutVar = setTimeout(() => {
            this.doneResizing();
        }, 400);
        this.hideMore = false;
        this.opacity = 0;
        this.games = this.mvsgame.games;
    }
    doneResizing() {
        //whatever we want to do
        if (document.querySelector('.desk-main-items')) document.querySelector('.desk-main-items')!.setAttribute('style', 'overflow:unset');
        this.games = this.mvsgame.games;
        this.navigationLinksHandler();
        this.checkForTouchDevice();
        this.screenWidth = window.innerWidth;
    }

    moreHover(hideMore: boolean) {
        if (!this.isTouchDevice) this.hideMore = hideMore;
        if (hideMore && !this.hideMore) this.hideMore = true;
    }
    moreClick(event: any) {
        this.hideMore = !this.hideMore;
        event.stopPropagation();
    }
    pushToCatergoryDivison() {
        if (this.categories.length == 0) this.makeCategories();
        this.moreCategory = [];
        this.categoriesLength = this.categories.length;
        this.iter = this.categories;
    }
    checkForTouchDevice() {
        try {
            this.isTouchDevice =
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Exception in setting isTouch Property: ' + error);
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    makeCategories() {
        this.categories = [];
        this.arr = this.beforeSports();
        for (let i = 0; i < this.arr.length; i++) {
            this.categories.push({
                obj: this.arr[i],
                name: this.arr[i]['title'],
                data: this.arr[i],
                vendorId: 'before',
                key: 'beforeSport',
            });
            // this.games.push({ 'title': this.arr[i]['title'], 'gameName': this.arr[i]['title'], 'VendorId': 'live_sports' });
        }
        this.arr = this.sportItems();
        for (let i = 0; i < this.arr.length; i++) {
            this.categories.push({
                obj: this.arr[i],
                name: this.arr[i]['tag']['name']['value'],
                vendorId: 'live_sports',
                data: this.arr[i]['tag']['id'],
                key: 'sportItem',
            });
            this.arr = this.sportItems();
            for (const item of this.arr) {
                this.categories.push({
                    obj: item,
                    name: item['tag']['name']['value'],
                    vendorId: 'live_sports',
                    data: item['tag']['id'],
                    key: 'sportItem',
                });
            }
        }
    }

    adjustNav() {
        this.isVirtualsInMore = false;
        // fetch all the nodes under main-items div tag
        const categoryNodes = [];
        let i;
        let categoriesNodeList = this.sportsNav?.nativeElement?.firstElementChild?.querySelectorAll('.navSpan');
        for (i = 0; i < categoriesNodeList.length; i++) categoryNodes.push(categoriesNodeList[i]);

        categoriesNodeList = this.sportsNav?.nativeElement?.firstElementChild?.querySelectorAll('.moreSpan');
        for (i = 0; i < categoriesNodeList.length; i++) categoryNodes.push(categoriesNodeList[i]);
        if (categoryNodes.length == 0) return;
        this.totalNavBarWidth = window.innerWidth - 180 - categoryNodes[0].clientWidth;
        const categoryWidths = [0];
        this.currentWidth = 0;
        const iterNodes = [];
        const moreNodes = [];
        this.moreCategory = [];
        this.categories = this.unquieItem(this.categories);
        this.categoriesLength = this.categories.length;
        for (i = 1; i <= this.categoriesLength; i++)
            if (categoryNodes[i] && categoryNodes[i].clientWidth) {
                categoryWidths.push(categoryNodes[i].clientWidth);
            }
        this.hideMore = true;
        this.opacity = 1;
        for (i = 1; i <= this.categoriesLength; i++) {
            if (this.currentWidth + categoryWidths[i] <= this.totalNavBarWidth) {
                this.currentWidth += categoryWidths[i];
                iterNodes.push(this.categories[i - 1]);
            } else {
                for (let j = i; j <= this.categoriesLength; j++) {
                    moreNodes.push(this.categories[j - 1]);
                    if (this.categories[j - 1].name == 'Virtuals') this.isVirtualsInMore = true;
                }
                break;
            }
        }
        this.iter = iterNodes;
        this.moreCategory = moreNodes;
        this.screenWidth = window.innerWidth;
    }
    unquieItem(categories: any) {
        const uniqueNames = new Set();
        const uniqueCategories = categories.filter((item: any) => {
            const name = item.name;
            if (!uniqueNames.has(name)) {
                uniqueNames.add(name);
                return true;
            }
            return false;
        });
        return uniqueCategories;
    }
    navigationLinksHandler() {
        this.isVirtualsInMore = false;
        this.activelinkstatus = false;
        // fetch all the nodes under main-items div tag
        const categoryNodes = [];
        let i;
        const navElement: any = this.sportsNav?.nativeElement?.firstElementChild;
        let categoriesNodeList;
        if (navElement && navElement.querySelectorAll('.navSpan')) {
            categoriesNodeList = navElement.querySelectorAll('.navSpan');
        }
        for (i = 0; i < categoriesNodeList.length; i++) categoryNodes.push(categoriesNodeList[i]);
        //push more items in list
        categoriesNodeList = this.sportsNav?.nativeElement?.firstElementChild?.querySelectorAll('.moreSpan');
        for (i = 0; i < categoriesNodeList.length; i++) categoryNodes.push(categoriesNodeList[i]);
        if (categoryNodes.length == 0) return;
        this.logger.info('ClientWidth', this.sportsNav.nativeElement.clientWidth);
        this.totalNavBarWidth = this.sportsNav.nativeElement.clientWidth - 50;

        const categoryWidths = [];
        this.currentWidth = 0;
        const iterNodes = [];
        const moreNodes = [];
        this.moreCategory = [];
        for (i = 0; i < categoryNodes.length; i++) categoryWidths.push(categoryNodes[i]['clientWidth'] == 0 ? 126 : categoryNodes[i]['clientWidth']);
        this.hideMore = true;
        this.opacity = 1;
        for (i = 0; i < categoryNodes.length; i++) {
            if (this.currentWidth + categoryWidths[i] <= this.totalNavBarWidth) {
                this.currentWidth += categoryWidths[i];
                iterNodes.push(this.games[i]);
                // this.ref.detectChanges();
            } else {
                iterNodes.pop();
                for (let j = i - 1; j < categoryNodes.length; j++) {
                    moreNodes.push(this.games[j]);
                }
                break;
            }
        }

        this.iter = iterNodes;
        this.games = iterNodes;
        this.moreCategory = moreNodes;
        this.screenWidth = window.innerWidth;
        if (this.moreCategory) {
            this.moreCategory.forEach((element: any) => {
                if (element?.gameName === this.currentUrlSpecificString) {
                    this.activelinkstatus = true;
                }
            });
        }
        this.ref.detectChanges();
    }

    adjustNavOnLandscapeMode() {
        let hdrEle: any;
        let hdrHeight = 0;
        if (document.querySelector('header')) {
            hdrEle = document.querySelector('header');
            hdrHeight = hdrEle.offsetHeight;
        }
        if (
            window.matchMedia('(orientation:portrait)').matches ||
            (window.matchMedia('(orientation:landscape)').matches && window.innerHeight > 480)
        ) {
            if (document.querySelector('header')) {
                // eslint-disable-next-line no-console
                console.log('portrait- not an app');
                hdrEle = document.querySelector('header');
                hdrHeight = hdrEle.offsetHeight - 2;
                document.querySelector('vs-nav')!.setAttribute('style', 'top:' + hdrHeight + 'px;');
            }
        }
        if (window.matchMedia('(orientation:landscape)').matches) {
            // eslint-disable-next-line no-console
            console.log('landscape');
            if (window.innerHeight < 480) {
                // eslint-disable-next-line no-console
                console.log('mobile landscape');
                document.querySelector('vs-nav')!.setAttribute('style', 'top:unset !important');
            }
        }
    }
    evaluateExtendTopMenu() {
        let index: number = 0;
        if (this.extendTopMenuState && this.extendTopMenu && this.extendTopMenu.length > 0) {
            this.extendTopMenu.forEach((item: any) => {
                if (item.title) {
                    this.extendMenuDslSubscription = this.dslService.evaluateExpression(item.condition).subscribe((r) => {
                        item.dslResult = r;
                        index++;
                        if (index == this.extendTopMenu.length) {
                            this.extendMenuDslEvalCompleted = true;
                            index = 0;
                        }
                    });
                }
            });
            if (this.extendMenuDslEvalCompleted) this.extendTopMenu = this.extendTopMenu.filter((dsl: any) => dsl.dslResult);
        } else {
            this.extendMenuDslEvalCompleted = true;
        }
    }
    goToUrl(url: any) {
        this.navigation.goTo(url);
    }

    private reloadNavigation() {
        this.adjustNav();
        this.games = this.mvsgame.games;
        this.navigationLinksHandler();
        this.adjustNavOnLandscapeMode();
    }

    ngOnDestroy(): void {
        this.moreCategory = [];
        this.games = [];
    }
}
