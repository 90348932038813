import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { routeData } from '@frontend/vanilla/core';

import { HomeComponent } from './home/home.component';
import { homeResolver } from './home/home.resolver';
import { PlayRedirectComponent } from './play-redirect.component';

export const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        resolve: {
            initData: homeResolver,
        },
        data: routeData({ allowAnonymous: true }),
    },
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: 'virtualsports',
                children: [
                    {
                        path: '',
                        component: HomeComponent,
                        resolve: {
                            initData: homeResolver,
                        },
                        data: routeData({ allowAnonymous: true }),
                    },
                    {
                        path: 'launchEmbedded/:gameId/:vendorId',
                        component: HomeComponent,
                        data: routeData({ authorized: true }),
                    },
                    {
                        path: 'launchng/:gameId/:vendorId',
                        component: PlayRedirectComponent,
                        data: routeData({ authorized: true }),
                        // canActivate: [playRedirectGuard],
                    },
                    {
                        path: ':gamename',
                        component: HomeComponent,
                        data: routeData({ allowAnonymous: true }),
                    },
                ],
            },
            {
                path: '',
                component: HomeComponent,
                data: routeData({ allowAnonymous: true }),
            },
            {
                path: '**',
                loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
            },
        ],
    },
];
